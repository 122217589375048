<template>
    <svg
        viewBox="0 0 250 250"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M24.2299 205.771C25.0698 217.384 32.9165 227.028 43.4203 230.688C47.3163 241.937 58.0769 249.977 70.4681 249.977L70.4915 250H71.6597L116.66 246.682C124.393 246.098 131.426 242.57 136.449 236.729C138.365 234.509 139.907 232.009 141.052 229.346L141.403 229.813C147.431 238.014 156.917 242.897 166.8 242.897H168.015L212.01 239.65C227.875 238.458 239.814 224.72 238.669 208.995L226.52 44.4392C225.664 32.7463 217.808 23.0411 207.27 19.33C203.305 8.04483 192.513 0 180.071 0H178.833L133.833 3.31775C122.945 4.11215 113.926 10.9813 109.791 20.4206C104.043 12.1495 94.3 7.07944 83.8561 7.07944C83.0851 7.07944 82.3141 7.12617 81.5431 7.17289L81.543 7.1729L38.4822 10.3505C30.5149 10.9112 23.1084 14.8832 18.1551 21.1916C13.7159 26.8692 11.543 33.972 12.0804 41.215L24.2299 205.771ZM135.679 28.2945L179.744 25.0468H179.767C182.01 24.8833 183.646 26.5188 183.786 28.5047L195.936 193.084C196.052 194.837 194.697 196.682 192.454 196.846L149.37 200.023C147.618 200.14 145.305 199.066 143.903 197.173L94.8607 122.944L100.562 200.14C100.678 201.893 99.3233 203.738 97.0804 203.902L53.0149 207.15C51.0289 207.29 49.4168 205.678 49.2766 203.925L37.1271 39.3459C36.9635 37.3599 38.342 35.4907 40.328 35.3505L83.4121 32.173C85.6551 32.0094 88.2252 33.0608 89.3701 34.9767L138.155 109.229L132.478 32.2898C132.314 30.3038 133.926 28.4113 135.679 28.2945Z"
            fill="white" />
    </svg>
</template>
