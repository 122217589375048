<script lang="ts" setup>
import useEmitter from '@/Composables/useEmitter'
import { computed, type PropType } from 'vue'
import { useRoute } from '@/Composables/useRoute.ts'
import type { Article } from '@/Types/Models/Article'
import { ArrowPathIcon } from '@heroicons/vue/20/solid'
import { useDateFormat, useTimeAgo } from '@vueuse/core'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { useMakeRequest } from '@/Composables/useMakeRequest'

const props = defineProps({
    article: Object as PropType<Article>,
    full: {
        type: Boolean,
        default: false
    },
    showUpdates: {
        type: Boolean,
        default: false
    }
})

function formatDate (date: string) {
    // return useDateFormat(date, 'DD MMM YYYY hh:mm', { locales: usePageProps().app.locale }).value
    return useDateFormat(date, 'MMMM DD, YYYY', { locales: usePageProps().app.locale }).value
}

function timeagoDate (date: string) {
    const timeago = useTimeAgo(date).value
    return timeago.toLowerCase()
}

async function showModal () {
    return useMakeRequest(useRoute('api.article.updates', { article: props.article }), 'GET').then(response => {
        useEmitter.emit('article-updates-modal:show', {
            updates: response.updates
        })
    })
}
</script>

<template>
    <div class="flex items-center gap-1">
        <div
            v-tooltip="full ? timeagoDate(article.updated_at) : formatDate(article.updated_at)"
            class="text-inherit leading-[0]"
            itemprop="datePublished">
            {{ full ? formatDate(article.updated_at) : timeagoDate(article.updated_at) }}
        </div>
        <button
            v-if="article.hasBeenRepublished && showUpdates"
            v-tooltip="`Published: ${formatDate(article.date)} (${timeagoDate(article.date)})`"
            class="cursor-pointer"
            @click="showModal()">
            <span class="sr-only">Updated</span>
            <ArrowPathIcon class="h-4 w-4" />
        </button>
    </div>
</template>
