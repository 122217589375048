import FullWhite from './FullWhite.vue'
import MarkWhite from './MarkWhite.vue'
import NovoWhite from './NovoWhite.vue'
import FullColour from './FullColour.vue'
import MarkColour from './MarkColour.vue'
import NovoColour from './NovoColour.vue'

export default {
    FullColour,
    FullWhite,
    MarkColour,
    MarkWhite,
    NovoColour,
    NovoWhite
}
