import { useIsAuthed } from '@/Composables/useIsAuthed.js'
import { usePageProps } from '@/Composables/usePageProps.ts'
import type { Notification } from '@/Types/Notification'
import type { BroadcastNotification } from '@/Types/BroadcastNotification'

export function useBroadcastNotifications (successCallback: (notification: BroadcastNotification) => void): void {
    const isAuthed = useIsAuthed()
    const pageProps = usePageProps()

    if (isAuthed && window.Echo !== null) {
        window.Echo.private(`App.Models.User.${pageProps.auth.user.id}`)
            .notification(async (notification: BroadcastNotification) => {
                successCallback(notification)
            })
    }
}
