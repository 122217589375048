<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps({
    length: Number,
    max: Number
})

function getPercentage (number, percentage) {
    return parseInt(((percentage / 100) * number).toFixed(0), 10)
}

const defaultColor = 'text-zinc-900/50 dark:text-white'

const counterColour = computed(() => {
    if (props.max) {
        const currentLength = props.length
        const max = props.max

        const eightyPercent = getPercentage(max, 80)
        const ninetyPercent = getPercentage(max, 90)

        if (currentLength >= eightyPercent && currentLength < ninetyPercent) {
            return 'text-orange-400/75'
        }

        if (currentLength >= ninetyPercent) {
            return 'text-red-500/75'
        }
        return defaultColor
    }
    return defaultColor
})
</script>

<template>
    <p
        :class="counterColour"
        class="text-xs font-semibold">
        {{ length }}/{{ max }}
    </p>
</template>
