<script setup lang="ts">
import Card from '@/Components/UI/Card.vue'
import patternWhite from '~/app/patterns/white.png'
import TwitterX from '@/Components/Icon/TwitterX.vue'
import Facebook from '@/Components/Icon/Facebook.vue'
import UserAvatar from '@/Components/User/UserAvatar.vue'
import FollowButton from '@/Components/User/FollowButton.vue'
import VerifiedBadge from '@/Components/User/VerifiedBadge.vue'
import { Link } from '@inertiajs/vue3'
import { type PropType, ref } from 'vue'
import type { User } from '@/Types/Models/User'
import { usePlural } from '@/Composables/usePlural'
import { useRoute } from '@/Composables/useRoute.ts'
import { useIsBreakpoint } from '@/Composables/useIsBreakpoint.ts'
import { useCountArticles } from '@/Composables/useCountArticles.ts'
import { useFollowerCount } from '@/Composables/useFollowerCount.ts'
import { useLinkifyString } from '@/Composables/useLinkifyString.ts'
import { useFormatUsername } from '@/Composables/useFormatUsername.js'
import { DocumentTextIcon, UsersIcon } from '@heroicons/vue/24/outline'
import { useGetDomainFromUrl } from '@/Composables/useGetDomainFromUrl'
import { useGetFaviconFromUrl } from '@/Composables/useGetFaviconFromUrl.ts'

const props = defineProps({
    user: Object as PropType<User>,
    actions: {
        type: Boolean,
        default: true
    },
    small: {
        type: Boolean,
        default: false
    },
    bio: {
        type: Boolean,
        default: false
    },
    links: {
        type: Boolean,
        default: false
    },
    inline: {
        type: Boolean,
        default: false
    },
    cover: {
        type: Boolean,
        default: false
    }
})

const followerCount = useFollowerCount(props.user, 0)
const articleCount = useCountArticles(props.user, 0)
const bioClicked = ref<boolean>(false)
const showMore = ref<boolean>(false)

const isMobile = useIsBreakpoint('md')

function cleanUrl (url: string): string {
    return url.replaceAll('https://', '').replaceAll('www.', '')
}
</script>

<template>
    <div>
        <component
            :is="inline ? 'div' : Card"
            :class="small ? 'md:py-3 md:px-3' : ''"
            class="overflow-hidden">
            <component
                :is="user.canBeViewed ? Link : 'div'"
                v-if="cover"
                :href="useRoute('user.show', user)"
                :class="small ? '-mx-3 -mt-3 mb-3' : '-mx-5 -mt-5 mb-5 '"
                class="relative flex aspect-[3/1] bg-secondary">
                <div
                    v-if="user.cover"
                    v-lazy:background="props.user.cover?.medium ?? patternWhite"
                    class="absolute top-0 left-0 h-full w-full bg-cover bg-center" />
                <div
                    v-else
                    v-lazy:background="patternWhite"
                    class="absolute top-0 left-0 h-full w-full opacity-5 bg-[length:200px]" />
            </component>
            <div class="flex flex-row justify-between gap-4 lg:items-center">
                <component
                    :is="user.canBeViewed ? Link : 'div'"
                    :href="useRoute('user.show', user)"
                    :class="user.canBeViewed ? 'group' : 'cursor-default'"
                    class="flex items-center gap-2">
                    <div
                        :class="small ? 'w-9' : 'w-12'"
                        class="rounded-full bg-white">
                        <UserAvatar
                            :user="user"
                            class="w-full rounded-full ring-1 transition-all ring-primary/10 group-hover:opacity-80" />
                    </div>
                    <div class="flex flex-col">
                        <div class="flex items-center font-semibold transition-colors space-x-2 group-hover:text-primary dark:group-hover:text-primary-light">
                            <span :class="!bio ? 'text-sm' : ''">{{ user.name }}</span>
                            <VerifiedBadge
                                :user="user"
                                :class="small ? 'w-4' : 'w-5'" />
                        </div>
                        <div
                            class="-mt-1 text-gray-500 dark:text-gray-400"
                            :class="small ? 'text-xs' : 'text-sm'">
                            {{ useFormatUsername(user) }}
                        </div>
                    </div>
                </component>
                <FollowButton
                    v-if="actions && user.canBeFollowed"
                    :key="`following-${user.id}-${isMobile}`"
                    :icon-only="isMobile"
                    :small="small"
                    :user />
            </div>
            <div
                v-if="user.bio && bio"
                :class="showMore ? 'flex' : 'hidden md:flex'"
                class="mt-2 max-w-none prose md:ml-14">
                <p
                    itemprop="description"
                    :class="bioClicked ? '' : 'line-clamp-0 md:line-clamp-[4]'"
                    class="w-full text-sm text-zinc-900/75 linkable leading-[1.4rem]"
                    @click="bioClicked = true"
                    v-html="useLinkifyString(user.bio)" />
            </div>
            <div
                class="mt-4 flex flex-row justify-between gap-2 pl-1 md:mt-2.5 lg:items-center"
                :class="bio ? 'lg:ml-12 lg:pl-2' : ''">
                <div
                    class="flex items-center gap-4"
                    :class="small ? 'text-xs' : 'text-sm'">
                    <div class="flex items-center gap-2 text-gray-500 dark:text-gray-400">
                        <UsersIcon class="h-4 w-4" />
                        <span>{{ followerCount }} {{ usePlural('follower', followerCount) }}</span>
                    </div>
                    <div class="flex items-center text-gray-500 space-x-1 dark:text-gray-400">
                        <DocumentTextIcon class="h-4 w-4" />
                        <span>{{ articleCount }} {{ usePlural('article', articleCount) }}</span>
                    </div>
                </div>
                <ul
                    v-if="links"
                    class="flex items-center gap-2 text-zinc-900 dark:text-white">
                    <li
                        v-for="link in user.metadata.profile_links.slice(0, 3)"
                        :key="link.url">
                        <a
                            v-tooltip="link.label ? `${link.label} (${link.url})` : `${link.url}`"
                            :href="useRoute('link-out', {url :link.url})"
                            itemprop="sameAs"
                            rel="nofollow"
                            class="flex items-center text-sm text-zinc-900/75 space-x-2 hover:text-zinc-900 dark:text-white/75 dark:hover:text-white"
                            target="_blank">
                            <span class="sr-only">User web link to {{ cleanUrl(link.url) }}</span>
                            <TwitterX
                                v-if="useGetDomainFromUrl(link.url) === 'twitter.com' || useGetDomainFromUrl(link.url) === 'x.com'"
                                class="w-4"
                            />
                            <Facebook
                                v-if="useGetDomainFromUrl(link.url) === 'facebook.com'"
                                class="w-4"
                            />
                            <img
                                v-if="['facebook.com', 'twitter.com', 'x.com'].every((social) => useGetDomainFromUrl(link.url) !== social)"
                                :alt="`Webpage icon for ${cleanUrl(link.url)}`"
                                :src="useGetFaviconFromUrl(link.url)"
                                class="w-4"
                                height="16"
                                width="16">
                        </a>
                    </li>
                </ul>
            </div>
        </component>
    </div>
</template>
