<script setup lang="ts">
import { useAttrs } from 'vue'
import { cn } from '@/utils.ts'

defineProps({
    border: {
        type: Boolean,
        default: false
    }
})

const attrs = useAttrs()

defineOptions({
    inheritAttrs: false
})
</script>

<template>
    <div
        :style="attrs.style"
        :class="cn(
            `rounded-xl shadow-sm bg-white dark:bg-zinc-800 border-zinc-200 dark:border-zinc-700 p-4 card md:py-4 md:px-5 ${border ? 'border' : ''}`,
            attrs.class
        )">
        <slot />
    </div>
</template>
