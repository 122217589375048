<template>
    <svg
        viewBox="0 0 286 107"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M113.434 64.61L107.124 64.28L104.244 59.06C103.294 57.35 102.734 55.36 102.564 53.14C102.404 50.89 102.664 48.83 103.334 46.98L105.424 41.28L111.384 40.12C111.774 40.04 112.294 39.96 112.964 39.92C113.234 39.9 113.544 39.89 113.814 39.89H114.084L120.404 40.2L123.294 45.44C124.244 47.16 124.804 49.16 124.974 51.38C125.134 53.59 124.884 55.67 124.204 57.52L122.044 63.42L115.844 64.43C115.564 64.48 115.214 64.53 114.744 64.56C114.344 64.59 114.024 64.6 113.744 64.6H113.464L113.434 64.61Z"
            fill="#6E51FC" />
        <path
            d="M113.864 50.64C113.804 50.64 113.734 50.64 113.624 50.64C113.534 50.64 113.464 50.66 113.404 50.67C113.314 50.91 113.194 51.46 113.264 52.34C113.324 53.15 113.514 53.65 113.624 53.86C113.684 53.86 113.754 53.86 113.864 53.86C113.954 53.86 114.024 53.84 114.084 53.83C114.174 53.59 114.294 53.04 114.224 52.16C114.164 51.35 113.974 50.85 113.864 50.64Z"
            fill="white" />
        <path
            d="M17.4444 99.41C11.0644 99.41 5.69439 94.43 5.23439 88.07L0.03439 17.64C-0.19561 14.54 0.734389 11.5 2.63439 9.07C4.75439 6.37 7.92439 4.67 11.3344 4.43L29.7644 3.07C30.0944 3.05 30.4244 3.03 30.7544 3.03C35.2244 3.03 39.3944 5.2 41.8544 8.74C43.6244 4.7 47.4844 1.76 52.1444 1.42L71.4044 0H71.9344C78.3644 0 83.7644 5.01999 84.2344 11.44L89.4344 81.87C89.9244 88.6 84.8144 94.48 78.0244 94.99L59.1944 96.38H58.6744C54.4444 96.38 50.3844 94.29 47.8044 90.78L47.6544 90.58C47.1644 91.72 46.5044 92.79 45.6844 93.74C43.5344 96.24 40.5244 97.75 37.2144 98L17.9544 99.42H17.4544L17.4444 99.41Z"
            fill="#6E51FC" />
        <path
            d="M112.144 93.92C89.1544 93.92 72.5544 78.43 70.8544 55.37C69.9744 43.51 73.3944 32.54 80.4644 24.46C87.4944 16.43 97.9244 11.53 109.834 10.65C111.114 10.56 112.394 10.51 113.654 10.51C136.644 10.51 153.244 26 154.944 49.06C156.754 73.57 140.724 91.96 115.974 93.78C114.694 93.87 113.404 93.92 112.154 93.92H112.144Z"
            fill="#6E51FC" />
        <path
            d="M25.0244 106.99C18.6444 106.99 13.2744 102.01 12.8144 95.65L7.61438 25.22C7.38438 22.12 8.31438 19.08 10.2144 16.65C12.3344 13.95 15.5044 12.25 18.9144 12.01L37.3444 10.65C37.6744 10.63 38.0044 10.61 38.3344 10.61C42.8044 10.61 46.9744 12.78 49.4344 16.32C51.2044 12.28 55.0644 9.34 59.7244 9L78.9844 7.58H79.5144C85.9444 7.58 91.3444 12.6 91.8144 19.02L97.0144 89.45C97.5044 96.18 92.3944 102.06 85.6044 102.57L66.7744 103.96H66.2544C62.0244 103.96 57.9644 101.87 55.3844 98.36L55.2344 98.16C54.7444 99.3 54.0844 100.37 53.2644 101.32C51.1144 103.82 48.1044 105.33 44.7944 105.58L25.5344 107H25.0344L25.0244 106.99Z"
            fill="#6E51FC" />
        <path
            d="M119.724 101.5C96.7344 101.5 80.1344 86.01 78.4344 62.95C77.5544 51.09 80.9744 40.12 88.0444 32.04C95.0744 24.01 105.504 19.11 117.414 18.23C118.694 18.14 119.974 18.09 121.234 18.09C144.224 18.09 160.824 33.58 162.524 56.64C164.334 81.15 148.304 99.54 123.554 101.36C122.274 101.45 120.984 101.5 119.734 101.5H119.724Z"
            fill="#6E51FC" />
        <path
            d="M173.144 96.05C168.034 96.05 163.214 92.78 161.294 88.04L138.344 34.11C136.794 30.69 136.944 26.76 138.784 23.47C140.734 19.99 144.304 17.73 148.334 17.44L168.364 15.96C168.694 15.94 169.004 15.92 169.314 15.92C173.214 15.92 176.864 17.73 179.294 20.71C181.364 17.19 185.094 14.73 189.344 14.41L209.904 12.89H210.434C214.064 12.89 217.444 14.48 219.704 17.25C221.964 20.03 222.844 23.67 222.104 27.22L222.054 27.47L207.134 85.14L207.004 85.53C205.314 90.46 200.744 94.02 195.624 94.41L173.694 96.03H173.184L173.144 96.05Z"
            fill="#6E51FC" />
        <path
            d="M242.594 92.42C219.604 92.42 203.004 76.93 201.304 53.87C200.424 42.01 203.844 31.04 210.914 22.96C217.944 14.93 228.374 10.03 240.284 9.15001C241.564 9.06001 242.844 9.01001 244.104 9.01001C267.094 9.01001 283.694 24.5 285.394 47.56C286.274 59.42 282.854 70.39 275.784 78.47C268.754 86.5 258.324 91.4 246.424 92.28C245.144 92.37 243.854 92.42 242.604 92.42H242.594Z"
            fill="#6E51FC" />
        <path
            d="M71.7944 10.72L52.9344 12.11C52.1844 12.16 51.4944 12.97 51.5644 13.82L53.9944 46.75L33.1144 14.97C32.6244 14.15 31.5244 13.7 30.5644 13.77L12.1244 15.13C11.2744 15.19 10.6844 15.99 10.7544 16.84L15.9544 87.28C16.0144 88.03 16.7044 88.72 17.5544 88.66L36.4144 87.27C37.3744 87.2 37.9544 86.41 37.9044 85.66L35.4644 52.62L56.4544 84.39C57.0544 85.2 58.0444 85.66 58.7944 85.61L77.2344 84.25C78.1944 84.18 78.7744 83.39 78.7244 82.64L73.5244 12.2C73.4644 11.35 72.7644 10.65 71.8044 10.72H71.7944Z"
            fill="white" />
        <path
            d="M165.564 88.47C160.454 88.47 155.634 85.2 153.714 80.46L130.764 26.53C129.214 23.11 129.364 19.18 131.204 15.89C133.154 12.41 136.724 10.15 140.754 9.86L160.784 8.38C161.114 8.36 161.424 8.34 161.734 8.34C165.634 8.34 169.284 10.15 171.714 13.13C173.784 9.61 177.514 7.15 181.764 6.83L202.324 5.31H202.854C206.484 5.31 209.864 6.9 212.124 9.67C214.384 12.45 215.264 16.09 214.524 19.64L214.474 19.89L199.554 77.56L199.424 77.95C197.734 82.88 193.164 86.44 188.044 86.83L166.114 88.45H165.604L165.564 88.47Z"
            fill="#6E51FC" />
        <path
            d="M110.624 21.36C91.8644 22.74 80.2044 36.04 81.5744 54.58C82.9344 73.02 96.4244 84.45 115.184 83.06C133.944 81.67 145.604 68.38 144.234 49.84C142.874 31.4 129.384 19.97 110.624 21.36ZM113.754 63.88C107.464 64.34 102.854 59.97 102.344 53.04C101.824 46.01 105.744 41 112.024 40.54C118.314 40.08 122.924 44.45 123.434 51.38C123.954 58.41 120.034 63.42 113.754 63.88Z"
            fill="white" />
        <path
            d="M235.014 84.84C212.024 84.84 195.424 69.35 193.724 46.29C192.844 34.43 196.264 23.46 203.334 15.38C210.364 7.34999 220.794 2.44999 232.704 1.56999C233.984 1.47999 235.264 1.42999 236.524 1.42999C259.514 1.42999 276.114 16.92 277.814 39.98C278.694 51.84 275.274 62.81 268.204 70.89C261.174 78.92 250.744 83.82 238.844 84.7C237.564 84.79 236.274 84.84 235.024 84.84H235.014Z"
            fill="#6E51FC" />
        <path
            d="M202.694 16.06L182.554 17.55C181.804 17.61 180.884 18.21 180.744 19.18L174.494 50.61L163.694 20.44C163.314 19.61 162.514 19.03 161.554 19.1L141.524 20.58C140.674 20.64 140.194 21.43 140.564 22.15L163.644 76.38C163.914 77.11 164.824 77.79 165.674 77.73L187.204 76.14C188.054 76.08 188.964 75.26 189.224 74.49L203.974 17.47C204.134 16.71 203.654 15.99 202.694 16.07V16.06Z"
            fill="white" />
        <path
            d="M267.094 40.77C265.734 22.33 252.244 10.9 233.494 12.29C214.734 13.68 203.074 26.97 204.444 45.51C205.804 63.95 219.294 75.38 238.054 73.99C256.814 72.6 268.474 59.32 267.104 40.77H267.094Z"
            fill="white" />
        <path
            d="M249.164 47.7747L228.036 29.6146C227.286 28.9702 226.156 29.0556 225.512 29.8053L221.014 35.038C220.37 35.7877 220.455 36.9179 221.205 37.5623L242.333 55.7224C243.083 56.3667 244.213 56.2814 244.857 55.5317L249.355 50.299C249.999 49.5493 249.914 48.4191 249.164 47.7747Z"
            fill="#6E51FC" />
        <path
            d="M240.319 28.7003L222.159 49.8282C221.515 50.5779 221.6 51.7081 222.35 52.3525L227.582 56.8501C228.332 57.4945 229.462 57.4092 230.107 56.6595L248.267 35.5315C248.911 34.7818 248.826 33.6516 248.076 33.0072L242.843 28.5096C242.094 27.8652 240.964 27.9506 240.319 28.7003Z"
            fill="#6E51FC" />
    </svg>
</template>
