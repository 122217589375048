<script lang="ts" setup>
import Logo from '@/Components/Logo'
import Button from '@/Components/UI/Button.vue'
import GamePad from '@/Components/Icon/GamePad.vue'
import Compass from '@/Components/Icon/Compass.vue'
import Dashboard from '@/Components/Icon/Dashboard.vue'
import CategoryIconMap from '@/Configs/CategoryIconMap.ts'
import GamePadFilled from '@/Components/Icon/GamePadFilled.vue'
import CompassFilled from '@/Components/Icon/CompassFilled.vue'
import SearchPalette from '@/Components/Global/SearchPalette.vue'
import UserDropdown from '@/Components/Navigation/UserDropdown.vue'
import AuthModalTrigger from '@/Components/Auth/AuthModalTrigger.vue'
import NotificationsLink from '@/Components/Navigation/NotificationsLink.vue'
import ArticleCreateButton from '@/Components/Article/ArticleCreateButton.vue'
import NavigationMobileNav from '@/Components/Navigation/NavigationMobileNav.vue'
import NavigationNavBarItem from '@/Components/Navigation/NavigationNavBarItem.vue'
import { Link } from '@inertiajs/vue3'
import type { NavItem } from '@/Types/NavItem'
import { useRoute } from '@/Composables/useRoute.ts'
import { onMounted, onBeforeUnmount, ref } from 'vue'
import type { Category } from '@/Types/Models/Category'
import { usePageProps } from '@/Composables/usePageProps'
import { useIsAuthed } from '@/Composables/useIsAuthed.js'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import { HomeIcon as SolidHomeIcon } from '@heroicons/vue/20/solid'
import { useAnalyticsEvent } from '@/Composables/useAnalyticsEvent.ts'
import {
    CalendarIcon,
    UserCircleIcon
} from '@heroicons/vue/24/solid'
import {
    FireIcon, ArrowUpRightIcon, MagnifyingGlassIcon, Bars3BottomLeftIcon, XMarkIcon, HomeIcon
} from '@heroicons/vue/24/outline'

const navOpen = ref<boolean>(false)
const scrolled = ref<boolean>(false)

const scrollPosition = ref<number>(0)

const breakpoints = useBreakpoints(breakpointsTailwind)
const mobileOnly = breakpoints.smallerOrEqual('md')

const categories = usePageProps().app.categories.map((category: Category) => {
    return {
        title: category.title,
        url: useRoute('articles.categorised', category),
        icon: CategoryIconMap[category.title]
    }
})

function primaryNav (solidIcon = false, includeMobile = false): NavItem[] {
    const items: NavItem[] = [
        {
            title: 'Home',
            url: useRoute('home'),
            icon: solidIcon ? SolidHomeIcon : HomeIcon
        },
        {
            title: 'Browse',
            url: null,
            icon: solidIcon ? CompassFilled : Compass,
            children: [
                {
                    title: 'Latest Articles',
                    url: useRoute('articles.index'),
                    icon: FireIcon
                },
                {
                    title: 'On This Day',
                    url: useRoute('articles.on-this-day'),
                    icon: CalendarIcon
                },
                ...categories
            ]
        },
        // {
        //     title: 'Trending',
        //     url: useRoute('articles.trending'),
        //     icon: ArrowTrendingUpIcon,
        //     if: usePageProps().totalTrendingArticleCount > 12
        // },
        {
            title: 'Games',
            url: useRoute('games.index'),
            icon: solidIcon ? GamePadFilled : GamePad
        }
    ]

    if (includeMobile) {
        items.push({
            title: 'Dashboard',
            url: useRoute('dashboard.home'),
            if: useIsAuthed(),
            icon: Dashboard
        })

        items.push({
            title: 'Search',
            url: useRoute('search.index'),
            icon: MagnifyingGlassIcon
        })
        items.push({
            title: 'Log in',
            url: useRoute('login'),
            if: !useIsAuthed(),
            classes: 'bg-white text-zinc-950 border-zinc-200 dark:border-zinc-700 my-2 items-center justify-center text-center flex',
            icon: ArrowUpRightIcon
        })
        items.push({
            title: 'Sign up',
            url: useRoute('register'),
            if: !useIsAuthed(),
            classes: 'bg-primary text-white items-center justify-center text-center flex',
            icon: UserCircleIcon
        })
    }

    return items
}

const isMac = ref(false)

function canShowItem (item: NavItem): boolean {
    let canShow = (item.if !== undefined ? item.if : true)

    if (item.permission !== undefined && canShow) {
        canShow = item.permission === true
    }

    return canShow
}

const searchOpen = ref<boolean>(false)

function closeSearch () {
    searchOpen.value = false
}

function openSearch () {
    searchOpen.value = true

    useAnalyticsEvent('Open search palette')
}

const scrollListener = () => {
    scrollPosition.value = window ? window.scrollY : 0
    scrolled.value = scrollPosition.value > 0
}

onMounted(() => {
    isMac.value = navigator.platform.toUpperCase().indexOf('MAC') >= 0
    // window.addEventListener('scroll', scrollListener)
})

onBeforeUnmount(() => {
    // window.removeEventListener('scroll', scrollListener)
})
</script>

<template>
    <nav
        id="siteNav"
        class="sticky top-0 z-50 flex h-16 w-full border-b border-transparent text-base dark:md:border-white/10 md:border-zinc-200 transition-all bg-primary dark:bg-zinc-950 dark:text-white md:justify-center md:bg-white">
        <NavigationMobileNav
            v-if="mobileOnly"
            :menu="primaryNav(true, true)"
            :open="navOpen"
            @close="navOpen = false" />

        <button
            v-if="mobileOnly"
            :class="navOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'"
            class="absolute left-0 top-16 z-0 h-[calc(100dvh-4rem)] w-full bg-black/50 transition-all duration-500"
            @click="navOpen = false">
            <span class="sr-only">Close nav</span>
        </button>
        <div class="mx-auto flex w-full flex-wrap items-center px-5 md:justify-between">
            <div class="mr-2 flex w-8 md:hidden">
                <button
                    class="rounded-full text-white ring-0 ring-primary/30 xxs:-mt-0.5 focus:text-primary focus:text-white focus:ring-2 dark:text-white/75 md:focus-primary md:text-zinc-900/75"
                    @click="navOpen = !navOpen">
                    <span class="sr-only">Toggle mobile nav</span>
                    <Bars3BottomLeftIcon
                        v-if="!navOpen"
                        class="w-6" />
                    <XMarkIcon
                        v-else
                        class="w-6" />
                </button>
            </div>
            <div
                class="flex h-full w-6/12 items-center">
                <Link
                    :href="useRoute('home')"
                    prefetch>
                    <span class="sr-only">Link to home</span>
                    <Logo.FullColour class="hidden w-32 pl-4 transition-all hover:scale-105 md:flex lg:w-44 dark:md:hidden" />
                    <Logo.FullWhite class="hidden w-32 pl-4 transition-all hover:scale-105 md:hidden lg:w-44 dark:md:flex" />
                    <Logo.FullWhite class="flex w-40 md:hidden" />
                </Link>
                <div class="ml-6 hidden h-full items-center justify-center md:flex">
                    <ul class="flex h-full flex-row items-center gap-0.5">
                        <template v-for="(item, i) in primaryNav()">
                            <li
                                v-if="(typeof item.permission === 'undefined' || item.permission === true) && canShowItem(item)"
                                :key="i"
                                class="h-full "
                                :class="item.classes">
                                <NavigationNavBarItem :item="item" />
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
            <ul class="flex items-center justify-end gap-2 md:gap-2.5 ml-auto md:w-6/12">
                <li
                    v-if="useIsAuthed()"
                    class="hidden md:flex">
                    <ArticleCreateButton
                        text="New article" />
                </li>
                <li class="relative hidden md:flex">
                    <span
                        class="absolute top-1/2 z-10 hidden -translate-y-1/2 rounded bg-zinc-100 px-1 py-1 text-xs font-semibold text-zinc-700 right-2.5 dark:bg-zinc-900 dark:text-zinc-200 lg:flex">
                        <span v-if="isMac">⌘ K</span>
                        <span v-else>Ctrl + K</span>
                    </span>
                    <Button
                        variant="white"
                        :icon="MagnifyingGlassIcon"
                        icon-first
                        @click="openSearch">
                        <span class="lg:pr-20">
                            Search
                        </span>
                    </Button>
                </li>
                <li v-if="useIsAuthed()">
                    <NotificationsLink />
                </li>
                <li v-if="!useIsAuthed() && mobileOnly">
                    <AuthModalTrigger>
                        <template #trigger>
                            <Button
                                icon-only
                                :icon="UserCircleIcon"
                                variant="white" />
                        </template>
                    </AuthModalTrigger>
                </li>

                <li v-if="!useIsAuthed() && !mobileOnly">
                    <AuthModalTrigger>
                        <template #trigger>
                            <Button
                                class="flex"
                                variant="white"
                                :icon="ArrowUpRightIcon">
                                Log in
                            </Button>
                        </template>
                    </AuthModalTrigger>
                </li>
                <li v-if="!useIsAuthed() && !mobileOnly">
                    <AuthModalTrigger default-form="register">
                        <template #trigger>
                            <Button
                                class="flex"
                                :icon="UserCircleIcon">
                                Sign up
                            </Button>
                        </template>
                    </AuthModalTrigger>
                </li>
                <li v-if="useIsAuthed()">
                    <UserDropdown />
                </li>
            </ul>
        </div>
    </nav>
    <SearchPalette
        :show="searchOpen"
        @open="openSearch"
        @close="closeSearch" />
</template>
