<script setup lang="ts">
import Logo from '@/Components/Logo'
import AdContainer from '@/Components/Global/AdContainer.vue'
import SocialLinksMinimal from '@/Components/Global/SocialLinksMinimal.vue'
import { Link } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import { FireIcon } from '@heroicons/vue/24/outline'
import { CalendarIcon } from '@heroicons/vue/24/solid'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { useCurrentUrl } from '@/Composables/useCurrentUrl.js'

type Column = {
    title: string,
    children: Array<{
        title: string,
        url: string,
        target?: string,
        if?: boolean
    }>
}

const columns: Column[] = [
    {
        title: 'Browse',
        children: [
            ...usePageProps().app.categories.map(category => {
                return {
                    title: category.title,
                    url: useRoute('articles.categorised', category)
                }
            }),
            {
                title: 'Latest Articles',
                url: useRoute('articles.index')
            }
        ]
    },
    {
        title: 'Discover',
        children: [
            {
                url: useRoute('games.index'),
                title: 'Games'
            },
            {
                url: useRoute('search.index'),
                title: 'Search'
            },
            {
                url: useRoute('rss.index'),
                title: 'RSS Feeds'
            },
            // {
            //     url: useRoute('users.top'),
            //     title: 'Top users'
            // },
            {
                url: useRoute('articles.archived'),
                title: 'Archived articles'
            }
        ]
    },
    {
        title: 'Info',
        children: [
            {
                url: 'https://status.' + usePageProps().app.domain,
                title: 'Status',
                target: '_blank'
            },
            {
                url: useRoute('about-us'),
                title: 'About us'
            },
            {
                url: useRoute('features'),
                title: 'Features'
            },
            {
                url: useRoute('contact'),
                title: 'Contact us'
            }
        ]
    },
    {
        title: 'Boring bits',
        children: [
            {
                url: useRoute('privacy'),
                title: 'Privacy'
            },
            {
                url: useRoute('terms'),
                title: 'Terms & Conditions'
            },
            {
                url: '/sitemap.xml',
                title: 'Sitemap',
                target: '_blank'
            }
        ]
    }
]

const disabledAds: String[] = [
    useRoute('about-us')
]
</script>

<template>
    <div class="">
        <AdContainer
            v-if="!disabledAds.includes(useCurrentUrl())"
            ad-slot="aboveFooter"
            class="mb-4 px-4" />
        <footer class="mt-auto pt-4 text-white shadow-xl bg-zinc-900 dark:bg-zinc-950 md:pt-16">
            <div class="container">
                <div class="mt-8 flex w-full flex-col md:flex-row">
                    <div class="flex w-full flex-col pr-8 md:w-2/3 lg:w-1/3 lg:pr-32">
                        <Link :href="useRoute('home')">
                            <span class="sr-only">Link to homepage</span>
                            <Logo.FullWhite
                                class="w-full" />
                        </Link>
                        <SocialLinksMinimal
                            class="mt-6"
                            white />
                    </div>
                    <div class="flex w-full mt-12 md:mt-0 flex-wrap items-start md:w-1/2 lg:w-2/3">
                        <div
                            v-for="column in columns"
                            :key="column.title"
                            class="mb-8 flex w-1/2 flex-col md:mb-16 lg:mb-0 lg:w-1/4">
                            <h2 class="text-2xl font-heading">
                                {{ column.title }}
                            </h2>
                            <ul class="mt-2 flex flex-col space-y-2.5">
                                <li
                                    v-for="item in column.children.filter(item => typeof item.if === 'undefined' || item.if === true)"
                                    :key="item.title"
                                    class="font-light">
                                    <component
                                        :is="item.target ? 'a' : Link"
                                        :href="item.url"
                                        :target="item.target"
                                        :rel="item.target ? 'nofollow' : null"
                                        class="hover:underline">
                                        {{ item.title }}
                                    </component>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col items-center justify-between gap-4 border-t border-zinc-800 py-8 text-sm text-white/75 md:mt-8 md:flex-row">
                    <div>&copy; {{ new Date().getFullYear() }} {{ usePageProps().app.name }}. All Rights Reserved.</div>
                    <div>v{{ usePageProps().app.id }}</div>
                </div>
            </div>
        </footer>
    </div>
</template>
